import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import PropertyUploaderConfig from "../../config/property-uploader";
import { get, isEmpty, find } from "lodash";
import { GetAllItems, GetFormValuesByEvent } from "../../utility/Form";
import TooltipOverlay from "@components/ToottipOverlay/TooltipOverlay";
import Toaster from "../../components/Toaster/Toaster";
import TableSearchParams from "./Search";
import { getUser, getLoggedInUserOffices } from "../../utility/Auth";
import LoadingComp from "./loading/Loading";

const PublishedStatusColumn = [
  {
    name: 'Status',
    selector: row => row.published_at,
    cell: row => (
      row.published_at ? 'Published' : 'Draft'
    ),
    sortable: true,
    sortField: 'published_at',
    width: "9%"
  },
]

const TimeStampColumns = [
  {
    name: 'Created At',
    selector: row => row.createdAt,
    cell: row => (
      `${row.createdAt ? `${new Date(row.createdAt).toDateString()} ${new Date(row.createdAt).toLocaleTimeString()}` :
        ""}`
    ),
    sortable: true,
    sortField: 'createdAt'
  },
  {
    name: 'Updated At',
    selector: row => row.updatedAt,
    cell: row => (
      `${row.updatedAt ? `${new Date(row.updatedAt).toDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}` :
        ""}`
    ),
    sortable: true,
    sortField: 'updatedAt'
  }],
  UserColumns = [
    {
      name: 'Created By',
      cell: row => {
        return get(row, 'created_by.username') || (`${get(row, 'created_by.firstname')} ${get(row, 'created_by.lastname')}`)
      },
      sortable: true,
      sortField: 'created_by.username'
    },
    {
      name: 'Updated By',
      cell: row => {
        return get(row, 'created_by.username') || (`${get(row, 'created_by.firstname')} ${get(row, 'created_by.lastname')}`)
      },
      sortable: true,
      sortField: 'created_by.username'
    }];

const CommonTableComp = (props) => {
  const [deletedItem, setDeletedItem] = useState({});
  const [showToaster, setShowToaster] = useState(false);
  const [toasterData, setToasterData] = useState({});
  const [loginUser, setLoginUser] = useState(getUser());
  const [loggedInUserOffices, setLoginUserOffices] = useState(getLoggedInUserOffices());

  const getDeletedItemFunc = (item, toasterRes) => {
    if (!isEmpty(item)) {
      setDeletedItem(item);
    }
    setShowToaster(true);
    setToasterData(toasterRes?.data);
  }

  const setShowToasterFunc = (value) => {
    setShowToaster(value);
    setToasterData({});
  }

  const ActionColumn = [{
    name: 'Action',
    center: true,
    cell: action => {
      let isEnabledAtionBtns = ((!isEmpty(loginUser.hasSuperAdminRole)) ||
        (((props.parentPath === 'properties') || (props.parentPath === 'new-developments')) 
          ? (!isEmpty(find(loggedInUserOffices, function (userOffice) {
            return (action.office_crm_id === userOffice["my_home_id"])
        }))) : false));
      return (
        <>
          <ul className="list-inline m-0 action-wrapper">
            <li className="list-inline-item m-0">
              <TooltipOverlay
                label="Edit"
                link={`/${props.parentPath}/edit/${action.id}/`}
                icon="icon-edit"
                isEnabled={isEnabledAtionBtns}
              />
            </li>
            <li className="list-inline-item m-0">
              <TooltipOverlay
                label="Delete"
                icon="icon-delete"
                // handleClick={}
                getDeletedItem={getDeletedItemFunc}
                itemId={action.id}
                {...props}
                isEnabled={isEnabledAtionBtns}
              />
            </li>
          </ul>
        </>
      )
    },
    width: "10%"
  }];
  const TableConfig = get(PropertyUploaderConfig, `${props.parentPath}.table`);
  let tableColumns = TableConfig['columns'];

  if (!TableConfig.excludePublishedStatus) {
    tableColumns = tableColumns.concat(PublishedStatusColumn)
  }

  if (!TableConfig.excludeTimeStamps) {
    tableColumns = tableColumns.concat(TimeStampColumns)
  }
  if (!TableConfig.excludeUser) {
    tableColumns = tableColumns.concat(UserColumns)
  }
  if (!TableConfig.excludeAction) {
    tableColumns = tableColumns.concat(ActionColumn)
  }
  const [allItems, setAllItems] = useState({
    columns: tableColumns
  });
  const [Loading, setLoading] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState((TableConfig?.default?.sortOrder) || 'asc');
  const [sortColumn, setSortColumn] = useState((TableConfig?.default?.sortField) || tableColumns[0].sortField);
  const [pageNo, setPageNo] = useState(1);
  const [reloadParam, setRelaodParam] = useState();
  const [searchParam, setSearchParam] = useState();

  const GetAllItemsFunc = async (query) => {
    try {
      setLoading(true);
      const { data } = await GetAllItems(get(props, 'additionalParams.endPointName'), { query: query });
      setLoading(false);
      setAllItems({ columns: tableColumns, data: data.results });
      setTotalRows(get(data, 'pagination.total'));
    } catch (error) {
      console.log('GetAllItemsFunc error', error, props);
      setLoading(false);
    }
  }

  const FormPaginationQuery = (perpage, pageno, sortField, sortDirection, searchParam) => {
    let QueryCustomize = `${isEmpty(props.additionalParams.query) ? '' : `${props.additionalParams.query}&`}`;
    if (((props.parentPath === 'properties') || (props.parentPath === 'new-developments')) && (isEmpty(loginUser.hasSuperAdminRole)) && (!isEmpty(loggedInUserOffices))) {
      let officesParams = '';
      loggedInUserOffices.forEach((element, index) => {
        officesParams += `_where[_or][${index}][${'office_crm_id'}]=${element["my_home_id"]}&`;
      })
      QueryCustomize += officesParams;
    }
    // return `${QueryCustomize}${searchParam ? `${searchParam}&` : ""}_start=${pageno}&_limit=${perpage}&_sort=${sortField || sortColumn}:${sortDirection.toUpperCase()}`
    return `${QueryCustomize}${searchParam ? `${searchParam}&` : ""}page=${pageno}&pageSize=${perpage}&_sort=${sortField || sortColumn}:${sortDirection.toUpperCase()}`
  }

  const handleSort = async (column, sortDirection) => {
    setSortOrder(sortDirection)
    setSortColumn(column.sortField)
  };

  const handlePageChange = page => {
    setPageNo(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const FormSubmitSearch = (event, additionalParams) => {
    event.preventDefault();
    let SearchedParams = GetFormValuesByEvent(event, true);
    SearchedParams = { ...SearchedParams, ...additionalParams.fields };
    //Form search params
    let formedSearchParams = [];
    for (const key in SearchedParams) {
      if ((SearchedParams[key]) || (SearchedParams[key] === false)) {
        formedSearchParams.push(`${key}=${SearchedParams[key]}`)
      }
    }
    setSearchParam(formedSearchParams.join('&'));
  }

  useEffect(() => {
    GetAllItemsFunc(FormPaginationQuery(perPage, pageNo, sortColumn, sortOrder, searchParam));
  }, [perPage, sortColumn, sortOrder, pageNo, deletedItem, reloadParam, searchParam, props.isChildReload]);

  const PreSelectedChildProperties = row => {
    let isPreSelected = false;
    if (props?.childProperty?.update) {
      isPreSelected = props?.childProperty?.new_development === row?.new_development?.id;
    }
    return isPreSelected; 
  }

  return (
    <>
      {
        props.hideDefaultSearch ? "" :
          <TableSearchParams {...props} FormSubmitSearch={FormSubmitSearch} />
      }
      <section className="table-wrapper pt-3">
        <div className="dashboard-table">
          <DataTable
            title={props.menu}
            striped={true}
            noHeader
            defaultSortAsc={false}
            progressPending={Loading}
            progressComponent={<LoadingComp {...props} />}
            onSort={handleSort}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            highlightOnHover={true}
            {...props.tableParams}
            {...allItems}
            selectableRowSelected={PreSelectedChildProperties}
          />
        </div>
      </section>
      {
        showToaster && <Toaster
          handleClick={showToaster}
          setShowToast={(val) => setShowToasterFunc(val)}
          variant={toasterData.variant}
          heading={toasterData.heading}
          message={toasterData.message}
        />
      }
    </>
  )
}

export default CommonTableComp;
