import React from "react";
import { Button, Form } from 'react-bootstrap';
import PropertiesSearch from "../properties/Search";
import NewDevelopmentsSearch from "../new-developments/Search";
import { set, merge, isEmpty } from "lodash";

const TableSearchParams = (props) => {
  const handleSearchChange = (event, name) => {
    let selectedOptions = {};
    event.map((option, index) => {
      selectedOptions[`_where[_or][${index}][${name}]`] = option.value
    })
    //Remove existing values to reset
    //Retain the other field values
    if (!isEmpty(props?.additionalParams?.fields)) {
      Object.keys(props?.additionalParams?.fields).map((field, index) => {
        delete props?.additionalParams.fields[`_where[_or][${index}][${name}]`];
      })
    }
    set(props, `additionalParams.fields`, merge(selectedOptions, props.additionalParams?.fields));
  }

  const filterNumber = (str) => {
    if (!str) return 0
    str = str.toString()
    return parseInt(str.replace(/[^\d.]/g, ""), 10)
  }

  const CustomSearch = (menu) => {
    switch (menu) {
      case 'properties':
      case 'child-properties':
        return <PropertiesSearch
          handleSearchChange={handleSearchChange}
          handleTextChange={handleTextChange} />
      case 'new-developments':
        return <NewDevelopmentsSearch
          handleSearchChange={handleSearchChange}
          handleTextChange={handleTextChange} />
      default:
        break;
    }
  }

  const handleTextChange = (event, additionalParams = {}) => {
    const { name, value } = event.target;
    let modifiedValue = value;
    if (additionalParams.type) {
      switch (additionalParams.type) {
        case 'int':
          modifiedValue = parseInt(event.target.value)
          break;
        case 'float':
          modifiedValue = parseFloat(filterNumber(event.target.value))
          break;
        default:
          break;
      }
    }
    if (!isEmpty(props.additionalParams)) {
      if (!isEmpty(props.additionalParams.fields)) {
        props.additionalParams.fields[name] = modifiedValue;
      } else {
        props.additionalParams.fields = {
          [name]: modifiedValue
        };
      }
    } else {
      props.additionalParams.fields = {
        [name]: modifiedValue
      };
    }
  }

  return (
    <>
      <Form className="filter_form" noValidate onSubmit={event => event.preventDefault()}>
        <section className=
          {`table-wrapper pt-3 filter-section ${(props.parentPath === 'properties' || props.parentPath === 'child-properties' || props.parentPath === 'new-developments') ? 'res_filter_form' : ''}`}>
          <div className="d-flex justify-content-between pb-3">
            <ul className={`list-inline m-0`} >
              {/* <li className="list-inline-item mb-0"> */}
              <li className="list-inline-item drop_down col-2 department-list">
                {props?.searchBoxSuffixText ?
                  <input type="text" name="_q"
                    className="form-control form-search" placeholder={`Search by ${props?.searchBoxSuffixText}`}
                    onChange={(event) => handleTextChange(event)}
                  />
                  : <input type="text" name="_q"
                    className="form-control form-search" placeholder={`Search`}
                    onChange={(event) => handleTextChange(event)}
                  />
                }
              </li>
              {
                CustomSearch(props.parentPath)
              }
              {/* <li className="list-inline-item mb-0 search-button-prop"> */}
              <li className="list-inline-item search-button-prop">
                <Button
                  onClick={(event) => props.FormSubmitSearch(event, props.additionalParams)}
                  className="btn-primary-dashboard">Search
                </Button></li>
            </ul>
          </div>
        </section>
      </Form>
    </>
  )
}

export default TableSearchParams;