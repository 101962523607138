import React, { useEffect, useState } from "react";
import Select from 'react-select';
import Dropdown from 'react-bootstrap/Dropdown';
import { getUser, getLoggedInUserOffices } from "../../utility/Auth"
import {
  GetAllItems
} from "../../utility/Form";
import { isEmpty } from 'lodash';

const SearchComp = (props) => {
  const [loginUser, setLoginUser] = useState(getUser());
  const [loggedInUserOffices, setLoginUserOffices] = useState(getLoggedInUserOffices());
  const [offices, setOffices] = useState([]);
  const GetOffices = async () => {
    try {
      let data = [], cond = {
        isGQL: true,
        query: `where: {_limit: 0}`,
        fields: `id, Office_Name, my_home_id, Branch_Location, Publish, published_at`
      };
      if (loginUser.hasSuperAdminRole) {
        cond['query'] = `where: {_limit: -1}, sort: "Office_Name:ASC", publicationState: PREVIEW`;
      } else {
        const OfficesParams = loggedInUserOffices.map((element) => {
          return element['my_home_id']
        });
        cond['query'] = `where: {_limit: -1, my_home_id_in: ${OfficesParams}}, sort: "Office_Name:ASC", publicationState: PREVIEW`;
      }
      data = await GetAllItems('offices.getAll', cond);      
      setOffices(data?.data?.data?.offices?.map(office => {
        return {
          value: office['my_home_id'],
          label: `${office['Office_Name']} ${office['Branch_Location'] ? (`(${office['Branch_Location']})`) : ""}`,
          isDisabled: (!office.Publish) || isEmpty(office.published_at) || (!office['my_home_id'])
        }
      }));
    } catch (error) {
      console.log('SearchComp GetOffices error', error);
    }
  };
  useEffect(() => {
    GetOffices();
  }, []);
  return (
    <>
      <li className="list-inline-item mb-0 drop_down col-2 department-list">
        <Select
          name="office"
          options={offices}
          classNamePrefix="react2-select"
          placeholder="Offices"
          isSearchable={true}
          className="secondary-classname"
          isMulti={true}
          onChange={(event) => props.handleSearchChange(event, 'office_crm_id')}
        />
      </li>
      <li className="list-inline-item drop_down col-3 department-list">
        <Select
          name="status"
          options={[
            { value: "Exchanged", label: "Exchanged" },
            { value: "For Sale", label: "For Sale" },
            { value: "Sold", label: "Sold" },
            { value: "Sale Agreed", label: "Sale Agreed" },
            { value: "Let", label: "Let" },
            { value: "Let Agreed", label: "Let Agreed" },
            { value: "PostPoned", label: "PostPoned" },
            { value: "To Let", label: "To Let" },
            { value: "Withdrawn", label: "Withdrawn" },
          ]}
          classNamePrefix="react2-select"
          placeholder="Status"
          isSearchable={true}
          className="secondary-classname"
          isMulti={true}
          onChange={(event) => props.handleSearchChange(event, 'status')}
        />
      </li>
      <li className="list-inline-item drop_down col-3 department-list">
        <Select
          name="published_at_null"
          options={[{value: true, label: 'Draft'}, {value: false, label: 'Published'}]}
          classNamePrefix="react2-select"
          placeholder="Publication Status"
          isSearchable={true}
          className="secondary-classname"
          isMulti={true}
          onChange={(event) => props.handleSearchChange(event, 'published_at_null')}
        />
      </li>
      <Dropdown className="d-inline mx-2 minmax-dropdown" autoClose="outside">
        <Dropdown.Toggle id="dropdown-autoclose-outside">
          Min - Max Price
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="list">
            <input className="form-control"
              name="price_gte"
              placeholder="Min Price"
              onChange={(event) => props.handleTextChange(event, { type: 'float' })} />
          </Dropdown.Item>
          <Dropdown.Item as="list">
            <input className="form-control"
              name="price_lte"
              placeholder="Max Price"
              onChange={(event) => props.handleTextChange(event, { type: 'float' })} />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default SearchComp;
